@font-face {
    font-family: 'SourceSansPro';
    font-weight: normal;
    font-style: normal;
    src: url('SourceSansPro-Regular.ttf');
}

@font-face {
    font-family: 'RobotoCondensed';
    font-weight: normal;
    font-style: normal;
    src: url('RobotoCondensed-Regular.ttf');
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
