body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.language-select > div:first-child {
    align-items: center !important;
    display: flex !important;
}

.react-datepicker__navigation--years {
    background: none;
    line-height: 1.7rem;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: 0.45rem solid transparent;
    z-index: 1;
    height: 25px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    top: -10px;
    border-bottom-color: #222222;
}

.react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    top: 10px;
    border-top-color: #222222;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    appearance: textfield;
    -moz-appearance: textfield;
}

code {
    font-family: Consolas, 'courier new';
    color: crimson;
    background-color: #f1f1f1;
    padding: 2px;
    font-size: 105%;
}
