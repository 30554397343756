/* top */
.st0 {
    fill: #ccc;
}

/* letter */
.st1 {
    fill: #fff;
}

/* lines */
.st2 {
    fill: #aaa;
}

/* left */
.st3 {
    fill: #e5e5e5;
}

/* right */
.st4 {
    fill: #ddd;
}

/* background */
.st5 {
    fill: #ddd;
}

#mail-icon {
    opacity: 1;
    transition: all 1s ease-out;
}

#mail-icon.hidden {
    opacity: 0;
}

#mail-icon.sent {
    transform: translateY(-600px) scale(0.01);
}

#env-lid {
    transform-origin: 10px 0%;
    transform-box: fill-box;
    transition: transform 0.3s ease-out;
    transition-delay: 0.4s;
    transform: translateY(0px);
}

#mail-icon.toggled #env-lid {
    transition-delay: 0s;
    transform: scaleY(-1) translateY(-1.5px);
}

#mail-icon.closed #env-lid {
    transition-delay: 0.1s;
    transform: translateY(0px);
}

#env-paper {
    transform-origin: 50% 100%;
    transform-box: fill-box;
    transform: scaleY(0);
    transition: transform 0.4s ease-out;
    transition-delay: 0.4s;
}

#mail-icon.toggled #env-paper {
    transition-delay: 0s;
    transform: scaleY(1);
}

#mail-icon.closed #env-paper {
    transition: transform 0.5s ease-out;
    transition-delay: 0s;
    transform: scaleY(0);
}

#env-line-1,
#env-line-2,
#env-line-3 {
    transform-origin: 0% 50%;
    transform-box: fill-box;
    transform: scaleX(0) translateY(0);
    transition: transform 0.4s ease-out;
}

#env-line-1 {
    transition-delay: 0s;
}

#env-line-2 {
    transition-delay: 0.1s;
}

#env-line-3 {
    transition-delay: 0.2s;
}

#mail-icon.toggled #env-line-1 {
    transition-delay: 0.7s;
    transform: scaleX(1);
}

#mail-icon.toggled #env-line-2 {
    transition-delay: 0.6s;
    transform: scaleX(1);
}

#mail-icon.toggled #env-line-3 {
    transition-delay: 0.5s;
    transform: scaleX(1);
}

#mail-icon.closed #env-line-1 {
    transition: transform 0.5s ease-out;
    transition-delay: 0s;
    transform: translateY(50px);
}

#mail-icon.closed #env-line-2 {
    transition: transform 0.5s ease-out;
    transition-delay: 0s;
    transform: translateY(50px);
}

#mail-icon.closed #env-line-3 {
    transition: transform 0.5s ease-out;
    transition-delay: 0s;
    transform: translateY(50px);
}
